import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import {
  UserPlusIcon,
  UsersIcon,
  Cog6ToothIcon,
  AcademicCapIcon,
  PresentationChartLineIcon,
  BoltIcon,
  ChatBubbleLeftRightIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline'
import IframeResizer from 'iframe-resizer-react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | Coaching & Mentoring Services" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-black"></div>
    <div className="max-w-[1200px] mx-auto -mt-44 text-center">
      <p className="text-myblue text-3xl lg:text-4xl pb-4 pl-6 font-tbold text-left">
        Coaching &amp; Mentoring Services
      </p>
      <StaticImage
        src="../images/image-4.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-8 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          Our coaching involves understanding your thoughts and perspective.
          From this benchmark, we will place another perspective on top of yours
          – stretching and growing you to gently see a more empowering way. As a
          certified John Maxwell coach, we will see what you are going through
          now and what is up ahead. Coaching is foreseeing, paving the way, and
          coming alongside you while helping you achieve goals and overcome
          limiting paradigms.
        </p>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left mt-4">
          We offer two levels of coaching support:
        </p>
        <ul className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          <li className="mt-2">
            <span className="font-bold">Individual Coaching:</span> one on one
            private and confidential coaching
          </li>
          <li className="mt-2">
            <span className="font-bold">Group Coaching:</span> small setting
            onsite or via webinar and video technology
          </li>
        </ul>
      </div>
    </section>
    <section className="pt-24 pb-12 my-12 max-w-[1200px] mx-auto bg-slate-50">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <p className="text-2xl md:text-3xl mb-6 text-gray-800 text-center">
          There is a synergy of energy, commitment, and excitement that
          participants bring to a mastermind group. My Work and Well-Being
          Consulting facilitated groups offer a combination of masterminding,
          peer brainstorming, education, accountability, and support in a group
          setting to sharpen your business and personal skills. Our masterminds
          can help you and your organization succeed by bringing fresh ideas and
          a different perspective.
        </p>
        <p className="text-2xl md:text-3xl mb-6 text-myred text-center font-bold mt-16">
          Key Benefits of a Mastermind Group:
        </p>
        <p className="text-lg mb-4 text-gray-800">
          Study Leadership, Communications, and Growth principles/practices in a
          group setting, create an action plan and have the group hold you
          accountable for fulfilling your plan and goals
        </p>
        <ul className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          <li className="mt-2 inline-flex">
            <AcademicCapIcon className="w-6 h-6 mr-6" /> Increase your own
            experience and confidence
          </li>
          <li className="mt-2 inline-flex">
            <PresentationChartLineIcon className="w-6 h-6 mr-6" /> Sharpen your
            business and personal skills
          </li>
          <li className="mt-2 inline-flex">
            <BoltIcon className="w-6 h-6 mr-6" /> Add an instant and valuable
            support network
          </li>
          <li className="mt-2 inline-flex">
            <ChatBubbleLeftRightIcon className="w-6 h-6 mr-6" />
            Get honest feedback, advice, and brainstorming
          </li>
          <li className="mt-2 inline-flex">
            <ChatBubbleOvalLeftEllipsisIcon className="w-6 h-6 mr-6" />
            Borrow on the experience and skills of the other members
          </li>
          <li className="mt-2 inline-flex">
            <BeakerIcon className="w-6 h-6 mr-6" />
            Receive critical insights into yourself
          </li>
          <li className="mt-2 inline-flex">
            <UserPlusIcon className="w-6 h-6 mr-6" />
            Optimistic peer support in maintaining a positive mental attitude
          </li>
          <li className="mt-2 inline-flex">
            <UsersIcon className="w-6 h-6 mr-6" />A sense of shared endeavour –
            there are others out there!
          </li>
        </ul>
      </div>
    </section>
    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section className="py-24">
      <div className="h-80 mx-auto inline">
        <IframeResizer
          title="iframe embed"
          src="https://wallembed.famewall.io/carousel/mwwb-c?autoplay=1"
          checkOrigin={false}
          autoResize={true}
          scrolling={false}
          width="100%"
          style={{
            border: 0,
            width: `1px`,
            minWidth: `100%`,
          }}
        />
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/i4.jpg"
          alt="Decorative image depicting employee working together"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
